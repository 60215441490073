/* Importando a fonte Roboto do Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Estilos gerais para garantir que o rodapé fique no final da página */
html, body, #root, .App {
  height: 100%; /* Garantindo que todos os elementos pais tenham altura total */
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

/* O conteúdo principal deve crescer para preencher o espaço */
.main-content {
  flex-grow: 1; /* Faz com que o conteúdo principal ocupe o espaço disponível */
}

/* Estilos para o cabeçalho */
.navbar {
  margin-bottom: 20px;
}

/* Rodapé */
footer {
  width: 100%; /* Ocupa toda a largura da tela */
  font-size: 0.85rem;
  color: #fff;
  background-color: #007bff;
  padding: 1rem 0;
  text-align: center;
  position: relative; /* Deixa o rodapé sempre no final */
  bottom: 0;
}

/* Estilos para as imagens das batalhas */
.battle-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

/* Estilo para os nomes dos MCs */
.mc-name {
  font-family: 'Roboto', sans-serif; /* Aplicando a fonte Roboto */
  font-size: 1.1rem; /* Ajustando o tamanho da fonte */
  font-weight: 700; /* Usando peso de fonte em negrito */
  color: #333; /* Cor da fonte */
}

/* Estilo para o link do número da edição */
.edition-number-link {
  font-family: 'Roboto', sans-serif; /* Aplicando a fonte Roboto */
  font-size: 1rem; /* Ajustando o tamanho da fonte */
  font-weight: 500; /* Peso de fonte normal */
  color: #007bff; /* Cor do link */
  text-decoration: none; /* Removendo o sublinhado */
}

.edition-number-link:hover {
  text-decoration: underline; /* Adicionando sublinhado ao passar o mouse */
}
